<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 class="cpx-4" style="padding-top: 15px; padding-bottom: 15px">
        スラグ作成
      </h2>
      <form class="px-0">
        <CCol sm="12 px-0">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >スラグ名<span class="textMust">（必須）</span></label
          >
          <CInput
            class="cp-4 mb-0"
            placeholder=""
            v-model="dataSlag.slag_name"
            :isValid="errorField.slag_name"
            :invalidFeedback="messageError.slag_nameRequired"
          />
        </CCol>
        <!-- <CCol sm="12" lg="4" md="12">
          <label class="font-weight-bold">リンク設定</label>
          <div class="w-100">
            <b-form-select
              class="col-sm-12 col-lg-6 p-0"
              id="select-cate"
              v-model="dataSlag.link_setting"
              :options="settingLinkOptions"
            ></b-form-select>
          </div>
        </CCol> -->
        <div>
          <CCol sm="12 px-0">
            <label class="col-sm-12 font-weight-bold custom-text cp-label"
              >スラグ付与用会員登録URL</label
            >
            <CInput
              class="cp-4 mb-0"
              label=""
              placeholder=""
              v-model="dataSlag.register_url"
              :isValid="errorField.register_url"
              :invalidFeedback="messageError.register_urlRequired"
              disabled
            />
          </CCol>
          <CCol sm="12 px-0">
            <label class="col-sm-12 font-weight-bold custom-text cp-label"
              >スラグ付与用ログインURL</label
            >
            <CInput
              class="cp-4 mb-0"
              label=""
              placeholder=""
              v-model="dataSlag.login_url"
              :isValid="errorField.login_url"
              :invalidFeedback="messageError.login_urlRequired"
              disabled
            />
          </CCol>
          <CCol sm="12 px-0">
            <label class="col-sm-12 font-weight-bold custom-text cp-label"
              >スラグ付与後リダイレクトURL</label
            >
            <CInput
              class="cp-4 mb-0"
              label=""
              placeholder=""
              v-model="dataSlag.redirect_url"
            />
          </CCol>
        </div>
        <CCol sm="12 px-0">
          <label class="pl-3 font-weight-bold custom-text cp-label"
            >登録させるメルマガフォーム</label
          >
          <div class="cp-4 w-100 mb-0">
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="selectSlag"
              tag-placeholder=""
              placeholder=""
              label="text"
              track-by="value"
              :options="listMailMegas"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              style=""
              v-bind:class="{
                'error-input': errorMailMegaHiddenRequired,
              }"
            ></multiselect>
          </div>
        </CCol>
        <CCol sm="12 px-0">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >スラグ名（英字）</label
          >
          <CInput
            class="cp-4 mb-0"
            label=""
            placeholder=""
            v-model="dataSlag.slag_name_en"
          />
        </CCol>
        <CCol sm="12 px-0">
          <!-- <CTextarea
            label="スラグの説明"
            placeholder=""
            v-model="dataSlag.description"
          /> -->
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >スラグの説明</label
          >
          <div class="cp-4 w-100 mb-0">
            <textarea
              class="form-control col-sm-12"
              type="text"
              rows="5"
              v-model="dataSlag.description"
              placeholder="スラグの説明"
            />
          </div>
        </CCol>
      </form>
      <div class="d-flex justify-content-center py-4">
        <b-button
          class="mr-4 btn-color"
          variant="success"
          v-on:click.prevent="submit()"
          :disabled="isLoading"
          style="width: 120px"
          >編集完了</b-button
        >
        <button class="btn mr-4 btn-secondary back-btn" v-on:click="cancel()">
          戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FeedBack } from "@/utils/feedback.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateSlag",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      dataSlag: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        slag_name: "",
        slag_name_en: "",
        link_setting: 0,
        register_url: "",
        login_url: "",
        redirect_url: "",
        description: "",
      },
      typeMailOptions: [
        {
          text: "フォームHTML1",
          value: 1,
        },
        {
          text: "フォームHTML2",
          value: 2,
        },
      ],
      isLoading: false,
      isEdit: false,
      id: null,
      settingLinkOptions: [
        { text: "なし", value: 0 },
        { text: "あり", value: 1 },
      ],
      errorField: {
        slag_name: null,
        slag_name_en: null,
        register_url: null,
        login_url: null,
        redirect_url: null,
      },
      messageError: {
        slag_nameRequired: "",
        slag_name_enRequired: "",
        slag_name_enInvalid: "",
        register_urlRequired: "",
        login_urlRequired: "",
        redirect_urlRequired: "",
      },
      list_mailmega: "",
      listMailMegas: [],
      errorMailMegaHiddenRequired: false,
      page: 2,
      selectSlag: [],
      listSlag: [],
    };
  },
  computed: {
    ...mapGetters(["slagById", "message", "success", "error", "listMailMega"]),
  },
  created() {
    this.id = this.$route.params.id;
    const id = this.$route.params.id;
    const shop_id = this.shop_id;
    if (id) {
      this.id = id;
      this.isEdit = true;
      this.getSlagById({ id: id, shop_id: shop_id });
    }
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getlistMailMega(requestContent);
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = false;
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.go(-1);
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    slagById() {
      let dataSlag = this.slagById;
      let shopId = this.$route.params.shopId;
      let preUrl = window.location.origin;
      if (this.$route.params.shopId) {
        preUrl += shopId
          ? "/shop/" + localStorage.getItem(Constants.SHOP_ID)
          : "";
      }
      if (dataSlag.login_url) {
        dataSlag.login_url = preUrl + dataSlag.login_url;
      }
      if (dataSlag.register_url) {
        dataSlag.register_url = preUrl + dataSlag.register_url;
      }
      this.dataSlag = dataSlag;
      this.selectSlag = this.slagById.newsletters;
      if (this.slagById.newsletters !== undefined) {
        this.selectSlag = this.slagById.newsletters.map((item) => ({
          value: item.id,
          text: item.title,
        }));
      }
    },
    "dataSlag.slag_name"() {
      this.isSlagNameValid();
    },
    // "dataSlag.slag_name_en"() {
    //   this.isSlagNameEnValid();
    // },
    "dataSlag.register_url"() {
      this.isRegisterUrlValid();
    },
    "dataSlag.login_url"() {
      this.isLoginUrlValid();
    },
    listMailMega() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollMailMega);
      this.listMailMegas = this.listMailMega.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
  },
  methods: {
    ...mapActions({
      CreateSlag: "CreateSlag",
      getSlagById: "getSlagById",
      updateSlagById: "updateSlagById",
      getlistMailMega: "getlistMailMega",
    }),
    scrollMailMega() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getlistMailMega(requestContent);
      }
    },
    isSlagNameValid() {
      if (this.dataSlag.slag_name.trim() === "") {
        this.errorField.slag_name = false;
        this.messageError.slag_nameRequired = FeedBack.SLAG_NAME_NULL;
        return false;
      } else {
        this.errorField.slag_name = null;
        this.messageError.slag_nameRequired = "";
        return true;
      }
    },
    // isSlagNameEnValid() {
    //   let error;
    //   if (this.dataSlag.slag_name_en.trim() === "") {
    //     this.errorField.slag_name_en = false;
    //     this.messageError.slag_name_enRequired = FeedBack.SLAG_NAME_EN_NULL;
    //     error = true;
    //   } else {
    //     this.errorField.slag_name_en = null;
    //     this.messageError.slag_name_enRequired = "";
    //     error = false;
    //   }
    //   if (
    //     this.dataSlag.slag_name_en.trim() !== "" &&
    //     !this.dataSlag.slag_name_en.match(Constants.REGEX_TEXT)
    //   ) {
    //     this.errorField.slag_name_en = false;
    //     this.messageError.slag_name_enInvalid = FeedBack.INVALID;
    //     error = true;
    //   } else {
    //     this.errorField.slag_name_en = null;
    //     this.messageError.slag_name_enInvalid = "";
    //     error = false;
    //   }
    //   return !error;
    // },
    isRegisterUrlValid() {
      if (
        this.dataSlag.register_url &&
        this.dataSlag.register_url.trim() === ""
      ) {
        this.errorField.register_url = false;
        this.messageError.register_urlRequired = FeedBack.REGISTER_URL_NULL;
        return false;
      } else {
        this.errorField.register_url = null;
        this.messageError.register_urlRequired = "";
        return true;
      }
    },
    isLoginUrlValid() {
      if (this.dataSlag.login_url && this.dataSlag.login_url.trim() === "") {
        this.errorField.login_url = false;
        this.messageError.login_urlRequired = FeedBack.LOGIN_URL_NULL;
        return false;
      } else {
        this.errorField.login_url = null;
        this.messageError.login_urlRequired = "";
        return true;
      }
    },
    // isRedirectUrlValid() {
    //   if (
    //     this.dataSlag.redirect_url &&
    //     this.dataSlag.redirect_url.trim() === ""
    //   ) {
    //     this.errorField.redirect_url = false;
    //     this.messageError.redirect_urlRequired = FeedBack.REDIRECT_URL_NULL;
    //     return false;
    //   } else {
    //     this.errorField.redirect_url = null;
    //     this.messageError.redirect_urlRequired = "";
    //     return true;
    //   }
    // },
    async submit() {
      let error = false;
      if (this.dataSlag.slag_name.trim() === "") {
        this.errorField.slag_name = false;
        this.messageError.slag_nameRequired = FeedBack.SLAG_NAME_NULL;
        error = true;
      }
      // if (this.dataSlag.slag_name_en.trim() === "") {
      //   this.errorField.slag_name_en = false;
      //   this.messageError.slag_name_enRequired = FeedBack.SLAG_NAME_EN_NULL;
      //   error = true;
      // }
      // if (
      //   this.dataSlag.slag_name_en.trim() !== "" &&
      //   !this.dataSlag.slag_name_en.match(Constants.REGEX_TEXT)
      // ) {
      //   this.errorField.slag_name_en = false;
      //   this.messageError.slag_name_enInvalid = FeedBack.INVALID;
      //   error = true;
      // }
      if (this.dataSlag.link_setting == 1) {
        // if (this.dataSlag.register_url.trim() === "") {
        //   this.errorField.register_url = false;
        //   this.messageError.register_urlRequired = FeedBack.REGISTER_URL_NULL;
        //   error = true;
        // }
        // if (!this.dataSlag.register_url.match(Constants.REGEX_URL)) {
        //   this.errorField.register_url = false;
        //   this.messageError.register_urlRequired = FeedBack.URL_NOT_REGEX;
        //   error = true;
        // }
        // if (this.dataSlag.login_url.trim() === "") {
        //   this.errorField.login_url = false;
        //   this.messageError.login_urlRequired = FeedBack.LOGIN_URL_NULL;
        //   error = true;
        // }
        // if (!this.dataSlag.login_url.match(Constants.REGEX_URL)) {
        //   this.errorField.login_url = false;
        //   this.messageError.login_urlRequired = FeedBack.URL_NOT_REGEX;
        //   error = true;
        // }
        // if (
        //   this.dataSlag.redirect_url === null ||
        //   this.dataSlag.redirect_url.trim() === ""
        // ) {
        //   this.errorField.redirect_url = false;
        //   this.messageError.redirect_urlRequired = FeedBack.REDIRECT_URL_NULL;
        //   error = true;
        // } else if (!this.dataSlag.redirect_url.match(Constants.REGEX_URL)) {
        //   this.errorField.redirect_url = false;
        //   this.messageError.redirect_urlRequired = FeedBack.URL_NOT_REGEX;
        //   error = true;
        // }
      }
      if (!error) {
        if (this.selectSlag !== null) {
          this.selectSlag.map((item) => {
            this.listSlag.push(item.value);
          });
        }
        this.dataSlag.link_setting = 1;
        this.dataSlag.newsletter_id = this.listSlag;
        // this.dataSlag.shop_id = this.$route.params.shopId;
        this.isLoading = true;
        if (this.isEdit) {
          this.dataSlag.id = this.id;
          let dataReturn = await this.updateSlagById(this.dataSlag);
          if (dataReturn.success) {
            this.$toasted.success(this.message);
            this.isLoading = false;
          } else {
            this.$toasted.error(this.message);
            this.isLoading = false;
          }
        } else {
          this.CreateSlag(this.dataSlag);
        }
      } else {
        this.$toasted.error("必須項目のご入力をお願いします。");
      }
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.ml-select {
  margin-left: -15px;
  padding-left: 0px;
}
</style>
